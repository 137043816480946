<template>
  <v-card>
    <v-card-title>Student Lists</v-card-title>
  <v-data-table :headers="headers" :items="items"></v-data-table>

  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: 'Name', value: 'column1' },
      ],
      items: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$api.get('teacher_wise_students')
        .then(response => {
          const transformedData = [];
          response.data.results.forEach(item => {
            item.student_names.forEach(studentName => {
              const rowData = {
                column1: studentName,
                student_name: studentName,
              };
              transformedData.push(rowData);
            });
          });
          this.items = transformedData;
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>

<style>
/* Add any custom styles you need for your component */
</style>
